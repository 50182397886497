<template>
  <!-- <b-tabs vertical content-class="col-12 col-md-9 mt-1 mt-md-0" pills nav-wrapper-class="col-md-3 col-12" nav-class="nav-left"> -->
    <b-tabs content-class="pt-1" fill>
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Konsumen</span>
      </template>

      <alamat-konsumen />
      <!-- v-if="options.general"
        :general-data="options.general" -->
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="StoreIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Toko</span>
      </template>

      <alamat-toko />
    </b-tab>
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon icon="StoreIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Retur</span>
      </template>

      <alamat-retur />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import AlamatKonsumen from "./alamat/AlamatKonsumen.vue";
import AlamatToko from "./alamat/AlamatToko.vue";
import AlamatRetur from "./alamat/AlamatRetur.vue";

export default {
  components: {
    BTabs,
    BTab,
    AlamatKonsumen,
AlamatToko,
AlamatRetur,
  },
  data() {
    return {
      options: {},
    };
  },
  // beforeCreate() {
  //   this.$http.get("/account-setting/data").then((res) => {
  //     this.options = res.data;
  //   });
  // },
};
</script>
